import { gql, DocumentNode } from 'apollo-boost';
import fragments from 'utils/gql/fragments';

const whereLanguage = (lang: string | string[]): string =>
  `where: { language: { code: "${lang}" } }`;

export const indexQuery = (lang: string | string[]): DocumentNode => gql`
    {
        indexPages(${whereLanguage(lang)}) {
        head {
            title
            description
            ogType
            ogImage {
                url
            }
        }
        title
    }
    }
`;

export const errorsQuery = (lang: string | string[]): DocumentNode => gql`
    {
        errorPages(${whereLanguage(lang)}) {
        title
        description
        instruction
        type
        head {
            title
            description
            ogType
            ogImage {
                url
            }
        }
    }
    }
`;

export const notFoundQuery = (): DocumentNode => gql`
  {
    errorPages {
      title
      description
      instruction
      type
      head {
        title
        description
        ogType
        ogImage {
          url
        }
      }
    }
  }
`;

export const filmsQuery = (lang: string | string[]): DocumentNode => gql`
    {
        moviePages(${whereLanguage(lang)}) {
        title
        linkTitle
        urlTitle
        index
        release {
            date
            text
        }
        distributor {
            company
            title
        }
        premiere {
            festival
            title
        }
        quotes {
            quote
            author
        }
        imageLinks {
            image {
                url
            }
            title
            label
            url
        }
        description {
            bold
            normal
        }
        authors {
            name
            title
        }
        awards {
            image {
                url
            }
            main
            footerText
            url
        }
        press {
            image {
                url
            }
            main
            footerText
            url
        }
        media {
            head {
                medium {
                    url
                }
                high {
                    url
                }
            }
            headMobile {
                source {
                    url
                }
            }
            body {
                source {
                    url
                }
                zIndex
            }
            awardsPress {
                url
            }
            trailer {
                cover {
                    url
                }
                url
            }
        }
        featuredAwards {
            url
        }
    }
    }
`;

export const aboutPageQuery = (lang: string | string[]): DocumentNode => gql`
    {
        aboutPages(${whereLanguage(lang)}) {
        name
        background {
            url
        }
        backgroundMobile {
            url
        }
        description
        title
        route
    }
    }
`;

export const newsPageQuery = (lang: string | string[]): DocumentNode => gql`
    {
        newsPages(${whereLanguage(lang)}) {
        name
        title {
            content
        }
        route
        background {
            url
        }
        backgroundMobile {
            url
        }
        posts {
            ...PostType
        }
    }
    }
    ${fragments.blogPost}
`;

export const pressPageQuery = (lang: string | string[]): DocumentNode => gql`
    {
        pressPages(${whereLanguage(lang)}) {
        name
        title {
            content
        }
        route
        background {
            url
        }
        backgroundMobile {
            url
        }
        posts {
            ...PostType
        }
    }
    }
    ${fragments.blogPost}
`;

export const menuQuery = (lang: string | string[]): DocumentNode => gql`
    {
        menuItems(${whereLanguage(lang)}) {
        items {
            id
            image {
                url
            }
            title
            route
        }
    }
    }
`;

export const commonsQuery = (lang: string | string[]): DocumentNode => gql`
    {
        commons(${whereLanguage(lang)}) {
        awardsPressHeader {
            awards
            press
        }
        footer {
            label
            copyright
            email
            instagram
        }
        playTrailer
        about
        landingItemLabel {
            more
        }
        featuredAwards
    }
    }
`;
