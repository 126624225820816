import { gql } from 'apollo-boost';

const blogPost = gql`
  fragment PostType on ComponentOtherBlogPost {
    title
    content
    footerImage {
      url
    }
    link
  }
`;

export default { blogPost };
