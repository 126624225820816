import React from 'react';
import NextHead from 'next/head';

export interface HeadProps {
  title: string;
  description: string;
  ogType: string;
  ogImage: {
    url: string;
  };
}

const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  ogType,
  ogImage,
}) => (
  <NextHead>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta
      name="apple-mobile-web-app-status-bar-style"
      content="black-translucent"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicon/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="/favicon/site.webmanifest" />
    <link
      rel="mask-icon"
      href="/favicon/safari-pinned-tab.svg"
      color="#101010"
    />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="msapplication-TileColor" content="#eeeeee" />
    <meta name="theme-color" content="#eeeeee" />
    <link rel="shortcut icon" href="/favicon.ico" />
    <meta name="msapplication-config" content="/favicon/browserconfig.xml" />

    {/* <meta property="og:url" content={fullPath} /> */}
    <meta property="og:type" content={ogType} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={`${ogImage ? ogImage.url : ''}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
    />
  </NextHead>
);

export default Head;
